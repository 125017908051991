import React from 'react';
import styled from 'styled-components';
import theme from '../../../utils/themes';

const Table = ({ jacobsRef, clientRef, balance }) => {
    return (
        <div>
            <TableStyle>
                <tbody>
                    <Row>
                        <TableHead>Jacobs Ref</TableHead>
                        <TableData>{jacobsRef}</TableData>
                    </Row>
                    <Row>
                        <TableHead>Council Ref</TableHead>
                        <TableData>{clientRef}</TableData>
                    </Row>
                    <Row>
                        <TableHead>Balance</TableHead>
                        <TableData>£{balance}</TableData>
                    </Row>
                </tbody>
            </TableStyle>
        </div>
    );
};

export default Table;

const TableStyle = styled.table`
    border-collapse: collapse;
    margin-bottom: 16px;
    font-variant-numeric: tabular-nums;
`;

const Row = styled.tr`
    width: 100%;
    font-size: 16px;
    :nth-child(odd) {
        background: ${theme.colors.grey};
    }
`;

const TableHead = styled.th`
    font-weight: 800;
    width: 140px;
    padding-top: 12px;
    text-align: left;
    border-bottom: none;
`;

const TableData = styled.td`
    max-width: 120px;
    padding-top: 12px;
    border-bottom: none;
`;
