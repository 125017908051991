import React from 'react';
import styled from 'styled-components';
import theme from '../../../utils/themes';
import Button from '../../Button';
import { Paragraph } from '../../Typography';

function CheckBoxWithButton({ disabled, checked, toggleChecked }) {
    return (
        <>
            <Container>
                <Input
                    name='agree'
                    type='checkbox'
                    onChange={toggleChecked}
                    checked={checked}
                />
                <Paragraph fontSize='15px !important' lineHeight='18px' m='0px'>
                    I confirm that I wish to setup an arrangement at the
                    frequency I have selected.
                </Paragraph>
            </Container>
            <Button width='100%' type='submit' disabled={disabled} height={40}>
                Submit
            </Button>
        </>
    );
}

export default CheckBoxWithButton;

const Container = styled.label`
    display: flex;
    justify-content: space-between;
    background: ${theme.colors.grey};
    padding: 8px 16px;
    align-items: centre;
    margin: 17px 0 17px;
`;

const Input = styled.input`
    margin-top: 4px;
    margin-right: 8px;
`;
