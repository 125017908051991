import React, { useEffect, useMemo } from 'react';
import AccountDetails from '../../components/PaymentPlan/AccountDetails/AccountDetails';
import HeroSection from '../../components/HeroSection';
import Section from '../../components/Section';
import { navigate } from 'gatsby';

const PaymentPlanAccount = ({ location }) => {
    const isAuth = useMemo(()=>{
        if (!location.state || !location.state.authenticated) {
            return false;
        }
        return true;
    },[location.state]);

    useEffect(() => {
        if (!isAuth) {
            navigate('/web-services/three-month-plan', {});
        }
    }, [isAuth]);

    return (
        <div>
            <HeroSection />
            <Section
                backgroundColor='grey'
                paddingTop='74px'
                paddingBottom='80px'
            >
                { isAuth && <AccountDetails /> }
            </Section>
        </div>
    );
};

export default PaymentPlanAccount;
