import React, { useState, useMemo } from 'react';
import { Heading3, Paragraph } from '../../Typography';
import Select from 'react-select';
import styled from 'styled-components';
import Submit from './Submit';
import fetch from '../../../utils/fetch';
import { navigate } from 'gatsby';
import { Input } from '../../Forms';

const options = [
    { value: 'week', label: 'Weekly' },
    { value: 'month', label: 'Monthly' },
];

const PaymentFrequency = ({
    monthlyAmount,
    monthlyFinalPayment,
    weeklyAmount,
    weeklyFinalPayment,
}) => {
    const [email, setEmail] = useState();
    const [selected, setSelected] = useState(options[0]);
    const [checked, setClicked] = useState(false);

    const repaymentAmount =
        selected.value === 'week' ? weeklyAmount : monthlyAmount;

    const finalPayment =
        selected.value === 'week' ? weeklyFinalPayment : monthlyFinalPayment;

    const isDisabled = useMemo(() => {
        if (!checked) {
            return true;
        }
        if (!selected) {
            return true;
        }
        return false;
    }, [checked, selected]);

    function handleChange(option) {
        setSelected(option);
    }

    function toggleChecked() {
        setClicked(!checked);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        //Do nothing if form submit is disabled
        if (isDisabled) {
            return;
        }

        try {
            const response = await fetch.post('/api/three-month-plan', {
                frequency: selected.value,
                email,
            });
            if (response.status !== 200) {
                navigate('/web-services/form-submit-error', {
                    replace: true,
                });
            }
            if (window) {
                navigate('/web-services/form-submitted', {
                    replace: true,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Container onSubmit={handleSubmit}>
            <Heading3>Select Payment Frequency</Heading3>
            <Select
                options={options}
                onChange={handleChange}
                value={selected}
            />
            <Paragraph mt='16px'>
                You will pay <Span>£{repaymentAmount}</Span> per{' '}
                <strong>{selected.value}</strong> over a period of three months,
                with a final payment of <Span>£{finalPayment}</Span>
            </Paragraph>
            <Input
                type='text'
                name='email'
                id='email'
                label='Enter an email address to receive confirmation (optional).'
                placeholder='Email Address'
                onChange={(e) => setEmail(e.target.value)}
            />
            <Submit
                checked={checked}
                toggleChecked={toggleChecked}
                disabled={isDisabled}
            />
        </Container>
    );
};

export default PaymentFrequency;

const Container = styled.form`
    margin-top: 0px;
    @media (max-width: 768px) {
        margin-top: 0px;
    }
`;

const Span = styled.span`
    background-color: #001389;
    color: white;
    padding: 2px 6px;
    border-radius: 2px;
    font-variant-numeric: tabular-nums;
    font-size: 16px;
    font-weight: 600;
`;
