import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Card from '../../Card';
import { Heading1 } from '../../Typography';
import Table from '../AccountDetails/Table';
import PaymentFrequency from '../AccountDetails/PaymentFrequency';
import fetch from '../../../utils/fetch';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const AccountDetails = () => {
    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        async function fetchDetails() {
            try {
                const response = await fetch.get('/api/user/details');

                const data = response.data.data;

                setUserDetails({
                    jacobsRef: data.case_id,
                    clientRef: data.client_ref,
                    balance: data.balance,
                    monthlyAmount: data.payments.monthly.amount,
                    monthlyFinalPayment: data.payments.monthly.last_amount,
                    weeklyAmount: data.payments.weekly.amount,
                    weeklyFinalPayment: data.payments.weekly.last_amount,
                });
            } catch (error) {
                console.log(error);
            }
        }
        fetchDetails();
    }, []);
    return (
        <Card padding='48px'>
            <Container>
                <div>
                    <Heading1>Account Details</Heading1>
                    <Table {...userDetails} />
                </div>
                <PaymentFrequency {...userDetails} />
            </Container>
        </Card>
    );
};

export default AccountDetails;
